const ua = navigator.userAgent;
export const ipad = /(iPad).*OS\s([\d_]+)/.test(ua);
export const isIphone =!ipad && /(iPhone\sOS)\s([\d_]+)/.test(ua);
export const isAndroid = /(Android)\s+([\d.]+)/.test(ua);
export const isWeixin = /MicroMessenger/i.test(ua);

/* const getIsPhone = () => { */
/*   if(/(iPhone|iPad|iPod|iOS|Android)/i.test(ua)) { */
/*     return true */
/*   } */
/*   return false; */
/* } */

export const isPhone = window.screen.width < 737;
export const isTablet = window.screen.width >= 737;
export const justPhone = (isIphone || isAndroid) && !isWeixin;

export default {
	isPhone,
	isTablet,
	isWeixin,
	isAndroid,
	justPhone,
	isIphone,
}



