import _ from 'lodash';
import { useMemo } from 'react';
import logo from '../images/logo.png';
// import logo_black from "../images/logo_black.png";
import logo_luojizhihui from '../images/logo_luojizhihui.png';
import logo_lianxiang from '../images/logo_lianxiang.png';
import logo_yuwen from '../images/logo_yuwen.png';
import logo_daofa from '../images/logo_daofa.png';
import logo_lishi from '../images/logo_lishi.png';
import dongtu1 from '../images/dongtu1.gif';
import dongtu2 from '../images/dongtu2.gif';
import Cookie from 'js-cookie';

export default function useInfoByHost() {
  const host = window.location.host;

  const appTitle = useMemo(() => {
    if (host === 'www.luojizhihui.com') {
      return '逻辑智慧';
    } else {
      return '小致教育';
    }
  }, [host]);

  const headerLeftLogo = useMemo(() => {
    if (host === 'www.luojizhihui.com') {
      return logo_luojizhihui;
    } else {
      return logo;
    }
  }, [host]);

  const dongtu = useMemo(() => {
    if (host === 'www.zhinengyanxiu.com') {
      return dongtu2;
    } else {
      return dongtu1;
    }
  }, [host]);

  const yanxiuButtonName = useMemo(() => {
    if (host === 'www.zhinengyanxiu.com' || host === 'www.luojizhihui.com') {
      return '研修社区';
    } else {
      return '教师发展数据平台';
    }
  }, [host]);

  const cangkuButtonName = useMemo(() => {
    if (host === 'www.zhinengyanxiu.com' || host === 'www.luojizhihui.com') {
      return '';
    } else {
      // return '教师档案平台';
      return '';
    }
  }, [host]);

  const insightButtonName = useMemo(() => {
    if (host === 'www.zhinengyanxiu.com' || host === 'www.luojizhihui.com') {
      return '';
    } else {
      // return '数据管理平台';
      return '';
    }
  }, [host]);

  const handleOpenYanxiuLink = () => {
    window.open('https://demox.xiaozhistar.com');
  };

  const handleOpenCangkuLink = () => {
    window.open('https://cangku.xiaozhistar.com');
  };

  const handleOpenInsightLink = () => {
    window.open('https://insight.xiaozhistar.com');
  };

  const handleOpenDemoLink = () => {
    if (host === 'www.xiaozhistar.com') {
      Cookie.get('accessToken')
        ? window.open('http://dm.xiaozhistar.com/dashboard?demo=school')
        : window.open('http://dm.xiaozhistar.com/login?demo=school');
    } else {
      window.open('http://dm.xiaozhistar.com/dashboard?demo=school');
    }
  };

  const ICPInfo = useMemo(() => {
    if (_.includes(host, 'xiaozhistar.com')) {
      return '京ICP备18062414号-1';
    } else if (_.includes(host, 'daofayanxiu.com')) {
      return '京ICP备18062414号-6';
    } else if (_.includes(host, 'meishuyanxiu.com')) {
      return '京ICP备18062414号-3';
    } else if (_.includes(host, 'shuxueyanxiu.com')) {
      return '京ICP备18062414号-9';
    } else if (_.includes(host, 'wuliyanxiu.com')) {
      return '京ICP备18062414号-10';
    } else if (_.includes(host, 'yuwenyanxiu.com')) {
      return '京ICP备18062414号-11';
    } else if (_.includes(host, 'zhinengyanxiu.com')) {
      return '京ICP备18062414号-8';
    } else if (_.includes(host, 'deyuyanxiu.com')) {
      return '京ICP备18062414号-63';
    } else if (_.includes(host, 'sizhengyanxiu.com')) {
      return '京ICP备18062414号-64';
    } else {
      return '京ICP备18062414号-1';
    }
  });

  return {
    appTitle,
    headerLeftLogo,
    dongtu,
    yanxiuButtonName,
    cangkuButtonName,
    insightButtonName,
    handleOpenYanxiuLink,
    handleOpenCangkuLink,
    handleOpenInsightLink,
    handleOpenDemoLink,
    ICPInfo,
  };
}
