import React from 'react';
import styled from 'styled-components';
import useInfoByHost from '../hooks/useInfoByHost';
import background_header from '../images/phone_bottom_bg.png';
import background_footer from '../images/yidongduanbeijingtu.png';
import comp1 from '../images/comp1.png';
import comp2 from '../images/comp2.png';
import comp3 from '../images/comp3.png';
import comp4 from '../images/comp4.png';
import fazhan from '../images/fazhan.png';
import jiaoyu from '../images/jiaoyu.png';
import keyan from '../images/keyan.png';
import shifan from '../images/shifan.png';
import beida from '../images/beida.png';

export default function App() {
  const { headerLeftLogo, dongtu } = useInfoByHost();

  return (
    <React.Fragment>
      <BgImg>
        <img alt='' className='header' src={headerLeftLogo} />
        <Title>
          <div className='title'>数智赋能教师发展</div>
          <div className='introduce'>聚焦场景·数智驱动·工具支撑·智库赋能</div>
          {/* <Button className="down-load">APP下载</Button> */}
        </Title>
        <Gif>
          <div className='gif-wrapper'>
            <img alt='' className='gif' src={dongtu} />
          </div>
        </Gif>
      </BgImg>
      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe67c;</span>数智赋能教师管理决策
          </div>
          <div className='en'></div>
          <div className='content'>
            聚焦领导关切，实时监测师资结构和队伍发展。
            <div style={{ height: 5 }}></div>
            局校一体，数据贯通，驾驶舱和数据报告一体化。
            <div style={{ height: 5 }}></div>
            生成式AI智能图表助手，将管理者的管理需求直接转化为可视化图表。
          </div>
        </div>
        <div className='bottom'>
          <div className='circle'></div>
          <img alt='' className='img' src={comp1} />
        </div>
      </Item>

      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe68e;</span>
            <b>数智赋能教师专业发展</b>
          </div>
          <div className='en'></div>
          <div className='content'>
            针对教师提问，及时推送经过大模型调优的个性化回答。
            <div style={{ height: 5 }}></div>
            推送和问题相关的师训资源，自动定位到相关的视频切片。
            <div style={{ height: 5 }}></div>
            建立“助手+社区”模式，互促共学，并为每个教师生成智能问学报告。
          </div>
        </div>
        <div className='bottom'>
          <img alt='' className='comp2' src={comp2} />
        </div>
      </Item>

      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe683;</span>
            工具应用赋能教师教研评
          </div>
          <div className='en'></div>
          <div className='content'>
            6大工具赋能教师日常教学和科研工作的方方面面。
            <div style={{ height: 5 }}></div>
            档案系统、教师职评、精准学习
            <div style={{ height: 5 }}></div>
            研修系统、听评课、调研库
          </div>
        </div>
        <div className='bottom2'>
          <img alt='' className='comp3' src={comp3} />
        </div>
      </Item>

      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe690;</span>
            智库引领
          </div>
          <div className='en'></div>
          <div className='content'>
            持续跟踪教育数改实践，教师发展科研成果转化。
            <div style={{ height: 5 }}></div>
            国家级课题承接单位、北京地区研究智库、港中文联合教科研研究
          </div>
        </div>
        <div className='bottom2'>
          <div className='block'></div>
          <img alt='' className='page' src={comp4} />
        </div>
      </Item>

      <ImgList>
        <div className='title'>合作单位</div>
        <div className='imgs'>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={keyan}
              onClick={() => window.open(' http://www.bjesr.cn/')}
            />
          </div>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={shifan}
              onClick={() => window.open(' https://www.bnu.edu.cn/')}
            />
          </div>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={jiaoyu}
              onClick={() => window.open('https://www.bjie.ac.cn/')}
            />
          </div>
        </div>
        <div className='imgs'>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={fazhan}
              onClick={() =>
                window.open(' http://www.cseds.edu.cn/edoas2/zlxh/index.jsp')
              }
            />
          </div>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={beida}
              onClick={() => window.open('http://www.gse.pku.edu.cn/')}
            ></img>
          </div>
        </div>
      </ImgList>

      <BgImgBottom>
        <div className='top'>
          <div className='item'>
            <div className='count'>10+</div>
            <div className='name'>覆盖省份</div>
          </div>
          <div className='item'>
            <div className='count'>30+</div>
            <div className='name'>服务区域</div>
          </div>
          <div className='item'>
            <div className='count'>7500+</div>
            <div className='name'>覆盖学校</div>
          </div>
          <div className='item'>
            <div className='count'>990,000+</div>
            <div className='name'>服务教师</div>
          </div>
        </div>
        <div className='bottom'>
          <div>marketing@xiaozhistar.com</div>
          <div>京ICP备18062414号-1 北京小致教育科技有限公司</div>
          <div>Copyright © 2020 All rights reserved</div>
        </div>
      </BgImgBottom>
    </React.Fragment>
  );
}

const Title = styled.div`
  height: max-content;
  color: #fff;
  background: #0033cc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > .title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 45px;
  }
  > .introduce {
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    margin: 10px 0 45px 0;
  }
`;

const Gif = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  > .gif-wrapper {
    width: 334px;
    height: 247px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation: showgif 3s;
    > .gif {
      width: 354px;
      height: 249px;
    }
  }
  @keyframes showgif {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BgImg = styled.div`
  height: 648px;
  width: 100%;
  background: url(${background_header}) no-repeat bottom;
  background-size: 100% 100%;
  > .header {
    height: 30px;
    width: auto;
    margin: 15px 20px;
  }
`;

const Item = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: 30px 0 70px 0;
  > .top {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > .title {
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      /* font-weight: bold; */
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      > .iconfont {
        font-family: 'iconfont' !important;
        font-size: 20px;
        font-style: normal;
        display: block;
        color: #0033cc;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 10px;
      }
    }
    > .en {
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 25px;
      text-align: center;
    }
    > .content {
      font-size: 14px;
      margin-bottom: 30px;
      padding: 0 20px;
      text-align: center;
    }
  }
  > .bottom {
    /* height: max-content; */
    height: 286px;
    position: relative;
    > .circle {
      height: 286px;
      width: 286px;
      border-radius: 50%;
      background: #0033cc;
    }
    > .img {
      position: absolute;
      width: 360px;
      // height: 185px;
      top: 64px;
      left: 37px;
    }
    > .block {
      position: absolute;
      right: 0;
      top: 0;
      height: 286px;
      width: 286px;
      border-radius: 50% 0 0 50%;
      background: #0033cc;
    }
    > .comp2 {
      position: absolute;
      // height: 295px;
      width: 360px;
      /* top: 6px; */
      left: 37px;
    }
  }
  > .bottom2 {
    height: max-content;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > .page {
      position: absolute;
      top: 55px;
      width: 360px;
      // height: 171px;
    }
    > .comp3 {
      // height: 225px;
      width: 360px;
    }
    > .block {
      position: absolute;
      right: 0;
      top: 0;
      height: 286px;
      width: 286px;
      border-radius: 50% 0 0 50%;
      background: #0033cc;
    }
  }
`;

const BgImgBottom = styled.div`
  margin-top: 60px;
  height: 236px;
  width: 100%;
  background: url(${background_footer}) no-repeat bottom;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > .top {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #fff;
    padding-bottom: 25px;
    > .item:last-child {
      > .count {
        border-right: 0;
      }
    }
    > .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > .count {
        border-right: 1px solid #fff;
        height: 24px;
        line-height: 24px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      > .name {
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
  > .bottom {
    display: flex;
    opacity: 0.7;
    width: 100%;
    font-size: 12px;
    flex-direction: column;
    background-color: rgba(21, 219, 149, 0.7);
    > div {
      color: #000;
      height: 25px;
      line-height: 25px;
      padding-left: 20px;
    }
  }
`;

const ImgList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .title {
    font-size: 18px;
    height: 28px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  > .imgs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    > .img-wrapper {
      margin-right: 20px;
      > .img {
        height: 20px;
      }
    }
  }
`;
