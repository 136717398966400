import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "./iconfont/iconfont.css";
import { isPhone } from "./hooks/sysTools";
import useInfoByHost from "./hooks/useInfoByHost";

import PcIndex from "./page/PcIndex";
import PhoneIndex from "./page/PhoneIndex";

export default function App() {
  const { appTitle } = useInfoByHost();

  return (
    <Wrapper>
      <Helmet>
        <title>{`${appTitle}`}</title>
      </Helmet>
      {isPhone ? <PhoneIndex /> : <PcIndex />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;
